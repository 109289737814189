<template lang="">
    <div ref="panel" class="offcanvas offcanvas-end border-0 shadow" tabindex="-1" id="user-details" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">User Details</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <p>ID: {{user?.ID}}</p>
            <p>Name: {{user?.display_name}}</p>
            <p>Email: {{user?.user_email}}</p>
            <p>Username: {{user?.user_login}}</p>
            <!-- <div>
            Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'UserDetails',

    props: {
        user: {
            type: Object, 
            default: function() {
                return {}
            }
        }
    }, 

    data() {
        return {
            panel: null
        }
    },

    methods: {
        show() {
            this.panel?.show();
        }, 

        setPanel() {
            let el = this.$refs.panel;
            if(!el) {
                setTimeout(this.setPanel, 500);
                return;
            }
            this.panel = new window.bootstrap.Offcanvas(el);
        }
    }, 

    mounted() {
        this.setPanel();
    },

    
}
</script>
<style lang="css" scoped>
.offcanvas {
    width: 600px;
}
</style>