<template lang="">
    <div class="container">
        <div class="row">
            <div class="col-6">
                <h1 class="fs-3 py-4">Data Import/Export</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <h3 class="fs-5">Downloads</h3>
                <div class="list-group">
                    <a href="#" @click.prevent="downloadDocuments" class="list-group-item list-group-item-action">Documents</a>
                    <a href="#" @click.prevent="downloadIndicators" class="list-group-item list-group-item-action">Indicators</a>
                    <a href="#" @click.prevent="downloadStatements" class="list-group-item list-group-item-action">Statements</a>
                    <a href="#" @click.prevent="downloadOpportunityTemplates" class="list-group-item list-group-item-action">Opportunity Templates</a>
                    <a href="#" @click.prevent="downloadStrategyTemplates" class="list-group-item list-group-item-action">Strategy Templates</a>
                </div>
            </div>
            <div class="col-6">
                <InputFileReader></InputFileReader>
            </div>
        </div>
    </div>
</template>
<script>

import InputFileReader from "@/components/input/InputFileReader.vue";


export default {
    name: 'Data',

    components: {
        InputFileReader
    },


    methods: {
        async downloadDocuments() {
            let options = {
                map: {
                    ID: 'ID',
                    post_title: 'post_title',
                    post_name: 'post_name',
                    post_excerpt: 'post_excerpt',
                    post_content: 'post_content',
                }
            };
            
            let object = await this.$store.dispatch('fetchTableOfContents', options);

            let content = JSON.stringify(object, null, 4);

            this.$root.download({
                content: content,
                fileName: 'documents.json'
            });
        },

        async downloadIndicators() {
            let object = await this.$store.dispatch('getIndicators');
            let content = JSON.stringify(object, null, 4);

            this.$root.download({
                content: content,
                fileName: 'indicators.json'
            });
        },

        async downloadStatements() {
            let object = await this.$store.getters.wp.run('getStatements', [], null);
            let content = JSON.stringify(object, null, 4);

            this.$root.download({
                content: content,
                fileName: 'statements.json'
            });
        }, 

        async downloadOpportunityTemplates() {
            let object = await this.$store.dispatch('fetchOpportunityTemplates');
            let content = JSON.stringify(object, null, 4);

            this.$root.download({
                content: content,
                fileName: 'opportunity_templates.json'
            });
        }, 

        async downloadStrategyTemplates() {
            let object = await this.$store.getters.wp.getStrategyTemplatesKind('all', {map: {
                ID: 'ID',
                post_title: 'post_title',
                post_content: 'post_content',
                post_excerpt: 'post_excerpt',
                post_name: 'post_name',
                post_thumbnail: 'post_thumbnail'
            }});

            let content = JSON.stringify(object, null, 4);

            this.$root.download({
                content: content,
                fileName: 'strategy_templates.json'
            });
        }, 


    }
}
</script>
<style lang="css" scoped>
    
</style>