<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100" data-cmp="AdminMain">
        <AppHeader></AppHeader>
        <section class="flex-fill container-fluid px-0 ">
            <div class="row g-0 mx-0">
                <div class="col-12">
                    <div>
                        <ul class="nav nav-tabs justify-content-center">
                            <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
                                <a class="nav-link" href="#"
                                    :class="[tab.name == current.tab? 'active': '']"
                                    @click.prevent="section(tab)"
                                    >{{tab.label}}</a>
                            </li>
                        </ul>
                    </div>



                    <div v-if="$route.params.section == 'users'">
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">#ID</th>
                                    <th scope="col">Display Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Username</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(user, index) in users" :key="index">
                                    <th scope="row">{{user.ID}}</th>
                                    <td>{{user.display_name}}</td>
                                    <td>{{user.user_email}}</td>
                                    <td>{{user.user_login}}</td>
                                    <td>
                                        <a href="#" @click.prevent="showUserDetails(user)">View</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>



                    <div v-if="$route.params.section == 'data'">
                        <Data></Data>
                    </div>


                </div>
            </div>
        </section>

        <AppFooter class="mt-5"></AppFooter>

        <UserDetails ref="userDetails" :user="current.user"></UserDetails>

    </div>
</template>

<script>

import Data from "./Data.vue";
import UserDetails from "./UserDetails.vue";
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";

export default {
    name: 'Admin',

    components: {
        Data,
        UserDetails,
        AppHeader,
        AppFooter
    },

    data() {
        return {
            users: [],
            tabs: [
                { label: 'Users', name: 'users'},
                { label: 'Opportunities', name: 'opportunities'},
                { label: 'Strategies', name: 'strategies'},
                { label: 'Companies', name: 'companies'},
                { label: 'Data', name: 'data'},
            ],
            panel: null,
            current: {
                user: null,
                tab: null
            }
        }
    },
    
    methods: {
        showUserDetails(user) {
            this.current.user = user;
            this.$refs.userDetails?.show();
        }, 

        section(tab) {
            this.current.tab = tab.name;
            
            this.$router.push({ path : `/admin/${tab.name}`});
        }, 

    },


    async mounted() {
        this.users = await this.$store.dispatch('fetchUsers');
        this.current.tab = this.$route.params?.section
        // this.setPanel();
    },
}
</script>
<style lang="css" scoped>
    
</style>