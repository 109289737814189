<template lang="">
    <div class="d-flex">
        <input class="text-file border btn ellipsis flex-fill fs-5 me-2 position-relative text-start text-muted disabled"
            v-model="currentFileName"/>
        
        <button class="btn-upload btn btn-primary text-center onverflow-hidden position-relative flex-grow-0 flex-shrink-0">
            <input type="file" name="opportunity-file" class="position-absolute top-0 start-0 w-100 h-100">
            <span>Choose File</span>
        </button>
    </div>
</template>
<script>

export default {
    name: 'InputFileReader',

    data() {
        return {
            fileName: '', 
            fileContent: null, 
            file: null
        };
    },

    computed: {
        currentFileName() {
            return this.fileName;
        }
    },


    methods: {
        async getFile(event) {
            const input = event.target;


            if ('files' in input && input.files.length > 0) {
                this.file = input.files[0];
                this.fileName = this.file.name;
                let textContent = await this.readFileContent(this.file);
                
                if(/\.appc$/.test(this.fileName)) {
                    textContent = await this.readAPPCFile(textContent);
                }
                
                this.fileContent = textContent;
                this.$emit('data', this.fileContent);

                console.log(this.fileName, this.fileContent, this.file);
            }
        },


        readFileContent(file) {
            const reader = new FileReader()
            return new Promise((resolve, reject) => {
                reader.onload = event => resolve(event.target.result);
                reader.onerror = error => reject(error);
                reader.readAsText(file);
            })
        },

        async readAPPCFile(appc) {
            appc = appc.split('\n').map((line)=>{
                return line.split('').reverse().join('');
            }).join('');

            let json = atob(appc.replace(/\s/g, ''));
            let object = JSON.parse(json);
            
            return object;
        },
    }, 

    mounted() {
        this.$el.querySelector('input[type="file"]').addEventListener('change', this.getFile);
    }
}
</script>


<style scoped>
.btn-upload {
    width: 140px;
    max-width: 140px;
}

.btn-upload input[type="file"] {
    opacity: 0;
    cursor: pointer;
}

textarea {

}

</style>